import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import {Logo} from './constants/Images';

import PageNotFound from './pages/PageNotFound';
import Home from './pages/Quiz';

const RoutesFile = props => {
  return (
    <div className="page-wrapper">
      <div id="loader" className="loader">
        <div className="loader-logo">
          <img src={Logo} alt="" />
        </div>
      </div>

      <div className="page-body-wrapper">
        <div className="page-header">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
        </div>
        <div className="page-content">
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route component={PageNotFound} />
            </Switch>
          </Router>
        </div>
      </div>
    </div>
  );
};

export default RoutesFile;
