const recaptchaKey = '';

export const quizQuestions = [
  {
    title: 'What’s your money motto?',
    anwers: [
      {
        type: '',
        text: 'Money doesn’t grow on trees',
      },
      {
        type: '',
        text: 'The best things in life are free',
      },
      {
        type: '',
        text: 'It’s better to give than to receive',
      },
      {
        type: '',
        text: 'I never look at the price tag',
      },
      {
        type: '',
        text: 'Money makes the world go round',
      },
      {
        type: '',
        text: 'Money is burning a hole in my pocket',
      },
    ],
  },
  {
    title: 'Complete this sentence: People achieve wealth by -',
    anwers: [
      {
        type: 'EM',
        text: 'making good choices.',
      },
      {
        type: '',
        text: 'prioritizing work',
      },
      {
        type: 'ONS',
        text: 'putting themselves first.',
      },
      {
        type: 'EM',
        text: 'being strategic with their savings',
      },
    ],
  },
  {
    title: 'Think quick! What’s the first word you associate with “money”',
    anwers: [
      {
        type: '',
        text: 'Irrelevant',
      },
      {
        type: '',
        text: 'Happiness',
      },
      {
        type: '',
        text: 'Status',
      },
      {
        type: '',
        text: 'Useful',
      },
    ],
  },
  {
    title: 'Would you rather:',
    anwers: [
      {
        type: 'EM',
        text: 'Stick to a strict budget',
      },
      {
        type: 'ONS',
        text: 'Get punched in the face',
      },
    ],
  },
  {
    title: 'You’re about to do some spending. What’s your shopping philosophy?',
    anwers: [
      {
        type: 'ONS',
        text: 'I buy things my friends are buying to stay up on the latest trends.',
      },
      {
        type: 'EM',
        text: 'I avoid shopping for things I consider unnecessary',
      },
      {
        type: 'EM',
        text: 'I look for the best deal when I’m shopping.',
      },
      {
        type: 'ONS',
        text: 'I buy things that make me happy.',
      },
    ],
  },
  {
    title: 'I will not buy something unless it is new (e.g., car, house)',
    anwers: [
      {
        type: 'ONS',
        text: 'Yes',
      },
      {
        type: 'EM',
        text: 'No',
      },
    ],
  },
  {
    title: 'Money is power.',
    anwers: [
      {
        type: 'ONS',
        text: 'Yes',
      },
      {
        type: 'EM',
        text: 'No',
      },
    ],
  },
  {
    title: 'Money is what gives life meaning.',
    anwers: [
      {
        type: 'ONS',
        text: 'Yes',
      },
      {
        type: 'EM',
        text: 'No',
      },
    ],
  },
  {
    title: 'You check your bank balance regularly',
    anwers: [
      {
        type: 'ONS',
        text: 'Yes',
      },
      {
        type: 'EM',
        text: 'No',
      },
    ],
  },
  {
    title: 'Money should be saved not spent.',
    anwers: [
      {
        type: 'ONS',
        text: 'Yes',
      },
      {
        type: 'EM',
        text: 'No',
      },
    ],
  },
];
