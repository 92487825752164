import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Button, Form, Modal} from 'react-bootstrap';
import {TaryanImg} from '../constants/Images';
import {quizQuestions} from '../constants';
import {appendFormData, validateEmail} from '../utils/Helpers';
import axios from 'axios';

const Quiz = () => {
  const [data, setData] = useState({
    name: '',
    email: '',
    results: [],
  });
  const [errors, setErrors] = useState({});
  const [startQuiz, setStartQuiz] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitQuiz, setSubmitModal] = useState(false);
  const [showThankYou, setThankYou] = useState(false);
  const [onsCount, setOnsCount] = useState(0);
  const [emCount, setEmCount] = useState(0);

  const [queIndex, setIndex] = useState(0);
  const [selectedQue, setSelectedQue] = useState(0);

  const updateData = e => {
    setData({...data, [e.target.name]: e.target.value});
  };

  const updateResult = answer => {
    const tempResults = [...data.results];
    tempResults[queIndex]['answer'] = answer;
    setData({...data, results: tempResults});
  };

  const validateData = () => {
    const errors = {};

    if (!data.name || data.name === '') {
      errors.name = 'Please enter your Name';
    }
    if (!data.email || data.email === '') {
      errors.email = 'Please enter your Email';
    } else if (data.email !== '' && !validateEmail(data.email)) {
      errors.email = 'Please enter valid Email';
    }

    return errors;
  };

  const submitQuizReport = async () => {
    const errs = validateData();
    setErrors(errs);
    if (!loading && Object.entries(errs).length === 0) {
      setLoading(true);

      let tempData = {...data};
      tempData['results'] = JSON.stringify(tempData['results']);
      tempData = appendFormData(tempData);

      // Send Email Now
      const url = 'https://quiz.themoneyplaybook.co/send-mail.php';
      axios({
        method: 'post',
        url: url,
        data: tempData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      })
        .then(res => {
          if (res.data.type === 'success') {
            let tempOns = 0;
            let tempEm = 0;
            for (let i = 0; i < data.results.length; i++) {
              if (data.results[i]['answer']['type'] === 'ONS') {
                tempOns = tempOns + 1;
              }
              if (data.results[i]['answer']['type'] === 'EM') {
                tempEm = tempEm + 1;
              }
            }

            setOnsCount(tempOns);
            setEmCount(tempEm);

            const tempResult = [];
            for (let i = 0; i < quizQuestions.length; i++) {
              tempResult[i] = {
                question: quizQuestions[i]['title'],
                answer: quizQuestions[i]['anwers'][0],
              };
            }

            setData({
              name: '',
              email: '',
              results: tempResult,
            });

            setIndex(0);
            setThankYou(true);
            setLoading(false);
            setStartQuiz(false);
            setSubmitModal(false);
          }
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (queIndex < quizQuestions.length) {
      setSelectedQue(quizQuestions[queIndex]);
    }
  }, [queIndex]);

  useEffect(() => {
    if (quizQuestions.length > 0) {
      const tempResult = [];
      for (let i = 0; i < quizQuestions.length; i++) {
        tempResult[i] = {
          question: quizQuestions[i]['title'],
          answer: quizQuestions[i]['anwers'][0],
        };
      }
      setData({
        ...data,
        results: tempResult,
      });
    }
  }, []);

  return (
    <Container>
      {!showThankYou && !startQuiz && (
        <div className="getting-started-wrapper">
          <Row>
            <Col md={8} sm={8} xs={12}>
              <div className="getting-started-content">
                <p>
                  Our relationship with money stems from our money behaviors and
                  is highly emotional. Oftentimes formed in our childhood.
                </p>

                <p>
                  The true first step to financial health is understanding your
                  own relationship with money and what makes you tick.
                </p>

                <p>
                  At The Money PlayBook®, we identify four types of money
                  relationships:
                </p>
                <p>
                  <ul>
                    <li>One-Night Stands</li>
                    <li>Dating</li>
                    <li>Engaged</li>
                    <li>Married</li>
                  </ul>
                </p>
                <Button
                  onClick={() => {
                    setStartQuiz(true);
                  }}>
                  Get Started
                </Button>
              </div>
            </Col>
          </Row>
          <div className="taryan-image">
            <img src={TaryanImg} alt="" />
          </div>
        </div>
      )}

      {startQuiz && (
        <div className="getting-started-wrapper">
          <Row>
            <Col md={12} sm={12} xs={12}>
              <div className="quiz-question">
                <h2>{selectedQue.title}</h2>
                <div className="answer-wrapper">
                  {selectedQue &&
                    selectedQue.anwers.length > 0 &&
                    selectedQue.anwers.map((item, index) => (
                      <div
                        className="ans-item"
                        key={`que-${queIndex}-ans-${index}`}>
                        <Form.Check
                          id={`que-${queIndex}-ans-${index}`}
                          type="radio"
                          name={`que-${queIndex}`}
                          label={item.text}
                          onChange={() => updateResult(item)}
                          checked={
                            data.results[queIndex]['answer']['text'] ===
                            item.text
                              ? true
                              : false
                          }
                        />
                      </div>
                    ))}
                </div>

                <div className="navigation-btns">
                  {queIndex !== 0 && (
                    <Button onClick={() => setIndex(queIndex - 1)}>Back</Button>
                  )}
                  {queIndex < quizQuestions.length - 1 && (
                    <Button onClick={() => setIndex(parseInt(queIndex) + 1)}>
                      Next
                    </Button>
                  )}
                  {queIndex === quizQuestions.length - 1 && (
                    <Button onClick={() => setSubmitModal(true)}>Finish</Button>
                  )}
                </div>
                {queIndex == 9 && (
                  <span className="info-text mt-4">
                    Almost done! This is the last question
                  </span>
                )}
              </div>
            </Col>
          </Row>

          {submitQuiz && (
            <Modal
              className="small"
              show={startQuiz}
              onHide={() => {
                setSubmitModal(false);
                setLoading(false);
              }}>
              <Modal.Header closeButton>
                <Modal.Title>Contact Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="fieldset">
                  <Form.Label>Name </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={data.name ? data.name : ''}
                    onChange={e => updateData(e)}
                  />
                  {errors.name && (
                    <span className="info-text error">{errors.name}</span>
                  )}
                </Form.Group>
                <Form.Group className="fieldset">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={data.email ? data.email : ''}
                    onChange={e => updateData(e)}
                  />
                  {errors.email && (
                    <span className="info-text error">{errors.email}</span>
                  )}
                </Form.Group>

                <Button className="mt-3" onClick={() => submitQuizReport()}>
                  {loading && <i className="fas fa-spinner fa-spin" />}
                  {!loading && 'Show Results'}
                </Button>
              </Modal.Body>
            </Modal>
          )}
        </div>
      )}

      {showThankYou && (
        <div className="getting-started-wrapper">
          <div className="getting-started-content text-center">
            <h2>Congratulations! Here is your results.</h2>

            {onsCount > emCount && (
              <>
                <p>
                  You are likely “dating” or having a “one-night stand” with
                  your money. You can take steps to improve your financial
                  health by digging deeper into understanding your money
                  mindset, creating a solid money management plan and following
                  it.
                </p>
                <p>
                  One-Night Stands - Your money leaves as fast as it comes in.
                  You don’t have a money management plan or follow a budget. You
                  spend money how you see fit without thinking twice.
                </p>
                <p>
                  Dating - You have a money management plan but following it
                  comes and goes. You have good weeks and you have off weeks. At
                  times, you follow your plan with ease but when life throws you
                  a curveball it knocks you off course.
                </p>
              </>
            )}

            {onsCount < emCount && (
              <>
                <p>
                  You are likely “engaged” or “married” to your money. At this
                  stage of your financial journey the best thing to do is stay
                  the course and be prepared for any storms.
                </p>
                <p>
                  Engaged - You have mastered your finances for the now but
                  haven’t quite reached the point of living for your future.
                </p>
                <p>
                  Married - There is hardly anything that can take you off
                  course. You successfully overcame negative money behaviors and
                  found a way to live for the now and your future.
                </p>
              </>
            )}
            <Button
              onClick={() => {
                setThankYou(false);
              }}>
              Go Home
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Quiz;
