// import Store from "../Store";
// import { updatePageLoadedDispatch } from "../Store/Actions/CommanAction";

window.onload = () => {
  //Store.dispatch(updatePageLoadedDispatch({ status: true }));

  fadeOutEffect();
};

const fadeOutEffect = () => {
  var fadeTarget = document.getElementById('loader');

  if (fadeTarget) {
    var fadeEffect = setInterval(function () {
      if (!fadeTarget.style.opacity) {
        fadeTarget.style.opacity = 1;
      }
      if (fadeTarget.style.opacity > 0) {
        fadeTarget.style.opacity -= 0.1;
      } else {
        fadeTarget.style.display = 'none';
        clearInterval(fadeEffect);
      }
    }, 100);
  } else {
    console.log('Loader not here');
  }
};
